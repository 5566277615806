import React from "react"

// Components
import Seo from "components/seo"
import Hero from "../components/hero"

// Page Sections
import LegalText from "./screens/legal/summary"

const Legal = () => {
  return (
    <React.Fragment>
      <Hero pageTitle="Legal">
        <p>An index of our legal notices, policies and required statements.</p>
      </Hero>
      <LegalText />
    </React.Fragment>
  )
}
export default Legal

export const Head = () => (
  <Seo
        title="Legal notices, Policies and Required Statements"
        description="An index of our legal notices, policies and required statements."
        image="/meta/legal.jpg"
  />
)
